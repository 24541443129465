import { LogLevel } from 'src/app/core/logging/logEntry';

// This file should contain ALL keys used by *any* environment
// the values in this file should be that of the DEVELOPMENT environment
// other named environment files (i.e. environment.smoke.ts) should include ONLY overrides to values as necessary
// Common configs point to nightly environment

export const commonConfig = {
    appId: 'RLZSCOUT',
    appName: 'Realize Scout',
    envName: 'DEV',

    production: false,
    test: false,

    mediaMaxUploadSize: {
        Image: 10485760, // 10MB
        Video: 293601280 // 280 MB
    },

    logging: {
        level: LogLevel.ALL,
        writers: ['console']
    },

    telemetry: {
        url: 'https://api.nightly.savvasrealizedev.com/telemetry-scout-nightly2/lst-telemetry',
        key: 'qqaOF5iRow7dmsfGo2gJz9E44AQLWZRO202GWl8b'
    },

    ssoUrl: 'https://nightly-sso.rumba.pk12ls.com/sso',
    ssoProfile: 'realize',

    authGatewayUrl: 'https://nightly-reader.savvasrealizedev.com/sapi',
    helpUrl: 'https://support.savvas.com/support/s/',

    api: {
        rosterSections:
            'https://roster-service.nightly.savvasrealizedev.com/roster-service/v1/sections',
        userProfile:
            'https://user-profile-service.nightly.savvasrealizedev.com/ups/api/v1/user/bulk/userids',
        programHierarchy:
            'https://lst-scout-nightly.lmsdev.net/api/v1/',
        jwt:
            'https://lst-scout-nightly.lmsdev.net/api/jwt',
        rootPrograms:
            'https://lst-scout-nightly.lmsdev.net/api/v1/rootPrograms',
        itemAttachment:
            'https://lst-scout-nightly.lmsdev.net/api/v1/',
        searchAssessmentItems:
            'https://lst-scout-nightly.lmsdev.net/api/v1/',
        signedMediaUrl:
            'https://us-central1-lst-scout-uat.cloudfunctions.net/getSignedUrl'
    },

    rbsConfig: {
        scope: 'rbs',
        clientId: 'GTrd4wvsJKWf6JkV0gbxHmgm3lQz20E7',
        grant_type: 'custom_castgc'
    },
    authContextId: '148966',

    firebase: {
        // the uat in the config represents the nightly environment
        apiKey: 'AIzaSyAAU5Bw4OaOqvv_BNI1nlQoMPaKct0_4Jg',
        authDomain: 'lst-scout-uat.firebaseapp.com',
        databaseURL: 'https://lst-scout-uat.firebaseio.com',
        projectId: 'lst-scout-uat',
        storageBucket: 'lst-scout-uat.appspot.com',
        messagingSenderId: '518171650289'
    },
};
